import { BasketResponseModel } from '~/dataModels/BasketModel/BasketResponseModel';
import { LOCALSTORAGE_BASKET_CURRENT, LOCALSTORAGE_PAYMENT } from '.';
import Cookies from 'js-cookie';
import { BASKETID, PaymentOptions } from '@utils/Constant';

export function getEmptyBasket() {
  return {
    basketId: '',
    summary: {
      allowPaymentLayby: false,
      discount: 0,
      finalTotal: 0,
      gst: 0,
      itemTotal: 0,
      promotionCode: '',
    },
    basketLines: [],
  } as BasketResponseModel;
}

// export function getCurrentBasket() {
//   if (typeof window !== 'undefined') {
//     const basket: string | null = localStorage.getItem(
//       LOCALSTORAGE_BASKET_CURRENT,
//     );
//     let currentBasket: BasketResponseModel;
//     if (basket === null) {
//       currentBasket = getEmptyBasket();
//     } else {
//       currentBasket = JSON.parse(basket) as BasketResponseModel;
//     }
//     return currentBasket;
//   } else {
//     return getEmptyBasket();
//   }
// }

// export function deleteBasketLineFromLocalStorage(basketLineId: string) {
//   const basket: string | null = localStorage.getItem(
//     LOCALSTORAGE_BASKET_CURRENT,
//   );
//   if (basket) {
//     const currentBasket = JSON.parse(basket) as BasketResponseModel;
//     const newBasketLines = currentBasket.basketLines.filter(
//       (b) => b.basketLineId !== basketLineId,
//     );
//     const newBasket = {
//       basketId: currentBasket.basketId,
//       basketLines: newBasketLines,
//     };
//     localStorage.setItem(
//       LOCALSTORAGE_BASKET_CURRENT,
//       JSON.stringify(newBasket),
//     );
//   }
// }

// export function saveBasketResponseToLocalStorage(
//   newBasket: BasketResponseModel,
// ) {
//   const updatedBasket = {
//     basketId: newBasket.basketId,
//     basketLines: newBasket.basketLines,
//     summary: newBasket.summary,
//   };
//   localStorage.setItem(
//     LOCALSTORAGE_BASKET_CURRENT,
//     JSON.stringify(updatedBasket),
//   );
// }

// export function setPaymentMethod(payment_type: number){
//     switch (payment_type) {
//       case PaymentOptions.CreditCard:
//         savePaymentMetodToLocalStorage('Credit Card')
//         break;
//     case PaymentOptions.Account2Account:
//         savePaymentMetodToLocalStorage('A2A')
//         break;
//     case PaymentOptions.Afterpay:
//         savePaymentMetodToLocalStorage('Afterpay')
//         break;
//     case PaymentOptions.DealerPaymentRequest:
//         savePaymentMetodToLocalStorage('DealerPaymentRequest')
//         break;
//     case PaymentOptions.Gem:
//         savePaymentMetodToLocalStorage('Gem')
//         break;
//     case PaymentOptions.KiwiplatesLayby:
//         savePaymentMetodToLocalStorage('Layby')
//         break;
//     default:
//         break;
//     }
//   }

// export function savePaymentMetodToLocalStorage(payment_type: string) {
//   // TODO: why?
//   Cookies.set(LOCALSTORAGE_PAYMENT, payment_type);
//   localStorage.setItem(LOCALSTORAGE_PAYMENT, payment_type);
// }

// export function getPaymentMetodToLocalStorage() {
//   localStorage.getItem(LOCALSTORAGE_PAYMENT);
// }

export const clearBasketAndCheckout = () => {
  localStorage.removeItem(BASKETID);
};
